body {
    font-family: 'Arial', sans-serif;
  }
  
  button {
    padding: 10px;
    margin: 10px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .cats-box {
    
    background-color: #fff;
    color: #333;
    border: 2px solid #333;
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .cats-box.dark-mode {
    background-color: #333;
    color: #fff;
    border-color: #fff;
  }

  .hidden
  {
    display: none;
  }
  